(function($) {

  /**
   * Loyalty Marketing Formatter
   */
  Drupal.behaviors.ELB_loyalty_mrkt_landing = {
    attach: function(context, settings) {
      Drupal.ELB.loadPersistenUserCookie();
      var persistentCookie = Drupal.ELB.getJSONCookie('persistent_user_cookie');
      var hasLoyalty = persistentCookie.is_loyalty_member - 0;
      var $landing = $('.loyalty_mrkt_ldng');
      if(hasLoyalty) {
        $landing.addClass('is_loyalty_member');
      }
      $('.loyalty_tiers .tier').first().addClass('default_tier');
      $('.loyalty_tiers .tier').each(function(index) {
        $(this).hover(function() {
          $(this).find('.tier_right').addClass('benefits_hover_tier_right');
          $(this).find('.tier_left').addClass('benefits_hover_tier_left_on');
          var previousTier = $(this).prevAll(".tier"),
              nextTier = $(this).nextAll(".tier");
          previousTier.find('.tier_left').removeClass('benefits_hover_tier_left_on').addClass("benefits_hover_tier_left_off");
          previousTier.find('.tier_right').removeClass('benefits_hover_tier_right').addClass("benefits_hover_tier_right");
          nextTier.find('.tier_left').removeClass('benefits_hover_tier_left_on').addClass("benefits_hover_tier_left_off");
          nextTier.find('.tier_right').removeClass('benefits_hover_tier_right');
        });
      });
      $('.loyalty_tiers').mouseleave(function() {
        var loyaltyTiers = $('.loyalty_tiers .tier');
        loyaltyTiers.find('.tier_right').removeClass('benefits_hover_tier_right');
        loyaltyTiers.find('.tier_left').removeClass('benefits_hover_tier_left_off').removeClass("benefits_hover_tier_left_on");
        loyaltyTiers.find(".tier_left.benefits_hover_tier_left_on").removeClass(".benefits_hover_tier_left_off");
      });
      // make sure buttons are hooked up
      site.loyalty.enrollmentBtn($landing);
      site.loyalty.signInBtn($landing);
      //call callback after html landing loads to visualise frontend changes (like gdpr agrees checkbox status, ect)
      if (typeof site.loyalty.landingLoadCallback === 'function') {
        site.loyalty.landingLoadCallback($landing);
      }
    }
  };

})(jQuery);
